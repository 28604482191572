import { render, staticRenderFns } from "./ColumnEmail.vue?vue&type=template&id=5b45ec18&"
import script from "./ColumnEmail.vue?vue&type=script&lang=js&"
export * from "./ColumnEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3501460270/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b45ec18')) {
      api.createRecord('5b45ec18', component.options)
    } else {
      api.reload('5b45ec18', component.options)
    }
    module.hot.accept("./ColumnEmail.vue?vue&type=template&id=5b45ec18&", function () {
      api.rerender('5b45ec18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/organization/customer-management/columns/ColumnEmail.vue"
export default component.exports